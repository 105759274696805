import momAndDad from './images/momAndDad.jpg';
import happyAnniversary from './images/happyAnniversary.jpg';
import './App.css';

function App() {
  return (
    <div className="container">
      <div className="image-container">
        <img src={momAndDad} alt="Mom And Dad" className='mom-and-dad' />
        <img src={happyAnniversary} alt="Happy Wedding Anniversary" className='happy-anniversary'  />
      </div>
      <div className='poem-container'>
        <p className="line">In the dance of years, thirty-five complete</p>
        <p className="line">A union strong, a love so sweet</p>
        <p className="line">Through seasons changing, side by side</p>
        <p className="line">In joy and sorrow, love's been your guide</p>
        <p className="empty-line"></p>
        <p className="line">Through laughter shared and tears that fell</p>
        <p className="line">Your hearts entwined a timeless spell</p>
        <p className="line">Hand in hand, you've faced life's song</p>
        <p className="line">Thirty-five years and still so strong</p>
        <p className="empty-line"></p>
        <p className="line">Through silver strands and lines that show</p>
        <p className="line">Your love's grown deeper, continues to grow</p>
        <p className="line">With each passing day, a chapter unfolds</p>
        <p className="line">A love story written, of treasures untold</p>
        <p className="empty-line"></p>
        <p className="line">Here's to you, on this special day</p>
        <p className="line">Thirty-five years in love's sweet sway</p>
        <p className="line">Through all the moments, highs and lows</p>
        <p className="line">Your love has blossomed, forever it grows</p>
        <p className="empty-line"></p>
        <p className="line anniversary-wish">WISH YOU A VERY HAPPY 35<sup>th</sup> WEDDING ANNIVERSARY</p>
      </div>
    </div>
  );
}

export default App;
